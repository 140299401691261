import { Row } from 'antd';
import Container from '../../common/Container';
import { SvgIcon } from '../../common/SvgIcon';
import { Button } from '../../common/Button';
import {
  HeaderSection,
  LogoContainer,
  CustomNavLinkSmall,
  Span,
} from './styles';

const MOBILE_LOGO_WIDTH = 50;
const MOBILE_LOGO_HEIGHT = 50;

const Header = () => {
  const MenuItem = () => {
    return (
      <>
        <CustomNavLinkSmall style={{ width: '180px' }} onClick={() => {}}>
          <Span>
            <Button
              onClick={() =>
                (window.location.href = 'mailto:contact@mujulabs.com')
              }
            >
              {'Contact'}
            </Button>
          </Span>
        </CustomNavLinkSmall>
      </>
    );
  };

  const mobile = window.innerWidth < 768;
  const logoWidth = `${mobile ? MOBILE_LOGO_WIDTH : 2 * MOBILE_LOGO_WIDTH}px`;
  const logoHeight = `${
    mobile ? MOBILE_LOGO_HEIGHT : 2 * MOBILE_LOGO_HEIGHT
  }px`;

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer
            to="/"
            aria-label="homepage"
            style={{
              marginTop: mobile ? '0' : '24px',
            }}
          >
            <SvgIcon
              src="logo-notext.png"
              width={logoWidth}
              height={logoHeight}
            />
          </LogoContainer>
          <MenuItem />
        </Row>
      </Container>
    </HeaderSection>
  );
};

export default Header;
