import { Row } from 'antd';
import Container from '../../common/Container';

import { FooterSection, NavLink } from './styles';

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = () => {
  return (
    <>
      <FooterSection>
        <Container>
          <Row
            justify="space-between"
            style={{ paddingTop: '0.5rem', alignItems: 'center' }}
          >
            <div
              style={{
                verticalAlign: 'top',
              }}
            >
              © 2024 MujuLabs. All rights reserved.
            </div>
            <NavLink to="/terms">{'Terms of Service'}</NavLink>
          </Row>
        </Container>
      </FooterSection>
    </>
  );
};

export default Footer;
